#Home-CallComponent {
    height: fit-content;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
}
#Home-CallComponent>div {
    padding: 5px;
}