html,
body {
  height: 100%;
}

#AMC-Teams-Root {
  padding: 5px;
  width: 100%;
  height: fit-content;
}

#Home {
  height: fit-content;
  width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ui-provider {
  height: 0;
}

.callBtn {
  margin-left: 17px !important;
  width: 101% !important;
  margin-top: -45px !important;
  border-radius: 9px;
  position: absolute;
  
}

.incomingCallToast {
  width: 100% !important;
}

.callOngoing {
  width: 320px !important;
}

::-webkit-scrollbar {
  display: none;
}

.custom-icon {
  font-size: 24px; 
  color: black;   
  text-align: center;
  border: 0px !important;
  background-color: #fff;
}

.ms-Button.ms-Button--primary.root-192 {
  /* Your custom styles here */
  background-color: #fff !important; 
  height: 56px; 
  padding: 0px;   
  border: 1px solid;  
  width: 56px;
  min-width: 56px;
}

.ms-Button.ms-Button--primary.root-181 {
  /* Your custom styles here */
  background-color: #fff !important; 
  height: 56px; 
  padding: 0px;   
  border: 1px solid;  
  width: 56px;
  min-width: 56px;
}

